<template>
    <div class="pageContainer Menu">
        <el-row type="flex" style="margin-bottom: 15px" justify="start">
            <el-input v-model="searchForm.major_name" clearable size="small" placeholder="专业名称" style="max-width: 200px; margin-right: 10px; margin-left: 10px"/>
            <el-cascader v-model="searchForm.class_id"
                         :props="{ label:'orgName',value:'orgId',emitPath:false}"
                         :options="classList1" size="small"
                         style="width: 300px; margin-right: 10px;" clearable
                         placeholder="班级名称"></el-cascader>

            <el-button size="small" icon="el-icon-search" @click="onSearch" plain type="primary">查询</el-button>
            <el-button size="small" icon="el-icon-refresh" @click="onReset" type="warning">重置</el-button>
            <el-button size="small" icon="el-icon-plus" @click="formAdd" type="primary">新增</el-button>
            <el-button v-if="false" size="small" @click="linkIvst" type="primary">linkIvst</el-button>
        </el-row>
        <el-row type="flex" style="margin-bottom: 20px" justify="start">
            <el-tabs type="border-card" style="width: 100%">
                <el-tab-pane label="专业列表"></el-tab-pane>
                <el-table size="mini" :data="tableData" style="width: 100%;margin-bottom: 20px;" row-key="id" border stripe
                          default-expand-all :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                    <el-table-column type="index" label="序号" width="80">
                    </el-table-column>
                    <el-table-column prop="major_name" label="专业" width="220"></el-table-column>

                    <el-table-column prop="classes" label="班级" min-width="180"></el-table-column>

                    <el-table-column prop="sort" label="排序" width="100">
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="180">
                        <template slot-scope="scope">
                            <el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消'
                                           icon="el-icon-info" iconColor="red"
                                           @confirm="handleDel(scope.row)" placement="left" title="是否确定删除？">
                                <el-tag slot="reference" size="mini" type="danger">删除</el-tag>
                            </el-popconfirm>
                            <el-tag size="mini" @click="handleModify(scope.row)" style="margin-left: 10px;">修改</el-tag>
                            <el-button v-if="scope.row.type!='class'&&false" @click="handleAdd(scope.row)" type="text"
                                       size="small">添加下级
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tabs>
        </el-row>


        <el-dialog :title="modalTitle" :visible.sync="isShowModal" :close-on-click-modal="false"
                   :destroy-on-close="true" custom-class="width_800 cus_dialog">
            <el-form :model="formData" ref="adminUserInfo" label-width="120px"
                     style="max-height: 450px;overflow-y: auto;min-height: 300px;">
                <el-form-item v-if="formData.pid==0" label="专业：" prop="major_name">
                    <el-input v-model.trim="formData.major_name" style="width: 50%" size="small" placeholder="专业名称"/>
                </el-form-item>

                <el-form-item v-if="formData.pid!=0" label="专业：" prop="major_name">{{ formData.pname }}</el-form-item>

                <el-form-item label="班级：">
                    <el-cascader ref="cascaderClass"
                                 v-model="formData.classids"
                                 :props="{multiple:true, label:'orgName',value:'orgId',emitPath:false}"
                                 :options="classList"
                                 :show-all-levels=false
                                 @change="handleChange"
                                 placeholder="请选择班级"
                                 style="width: 100%" size="small"></el-cascader>
                </el-form-item>


                <el-form-item label="排序：" prop="sort">
                    <el-input type="number" v-model.trim="formData.sort" style="width: 50%" size="small"/>
                </el-form-item>

            </el-form>
            <div slot="footer">
                    <el-button size="small" type="default" @click="closeDialog">取消</el-button>
                    <el-button size="small" type="primary" @click="submitForm">保存</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import api from '@/api/api';

export default {
    name: 'Menu',
    data() {
        return {
            isShowModal: false,
            modalTitle: '添加',
            formData: {},
            //table
            tableData: [],
            tableDataAll: [],
            classList: [],
            searchForm:{},
            classList1:[]
        }
    },
    watch: {
        'formData.pid'() {
            if (this.$refs.firstCascader) {
                this.$refs.firstCascader.dropDownVisible = false; //监听值发生变化就关闭它
            }
        },
    },
    mounted() {
        this.getList();
        this.getClass1()
    },
    methods: {
        linkIvst() {
            this.$router.push({
                name: 'ivstplan',
                params: {id: '1$92$s$3971$m'}
            })
        },
        getClass1() {
            this.$http.post("api/sys_office", {"parent_id": "0", pmid: "0"}).then((res) => {
                if(res.data&&res.data[0]&&res.data[0].children&&res.data[0].children.length>0){
                    this.deleteEmptyGroup(res.data[0].children).then(a => {
                        this.classList1 = a
                    })
                }
            });
        },
        getList() {
            let _this = this
            this.$http.post('api/sys_major_tree', {pid: 0}).then(res => {
                if (res.data && res.data.length > 0) {
                    res.data.map(a => {
                        if (a.children) {
                            a.children.map(b => {
                                b.type = 'class'
                                b.pname = a.major_name
                                // console.log(b.major_id)
                            })
                        }
                        let arr = [],ids = []
                        if(a.classNames&&a.classNames.length>0){
                            a.classNames.map(b=>{
                                arr.push(b.name)
                                ids.push(b.old_id)
                            })
                            a.classes = arr.join(',')
                            a.classes_ids = ids.join(',')
                        }
                    })
                    _this.tableData = res.data
                    _this.tableDataAll = res.data
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        // 处理没有children的分组
        deleteEmptyGroup(treeData) {
            return new Promise(resolve => {
                function traversal(data) {
                    data.map(item => {
                        for (let info in item) {
                            if (item['children']) {
                                if (item['children'].length > 0) {
                                    traversal(item['children'])
                                }
                                if (item['children'].length == 0) {
                                    delete item['children']
                                }
                            }
                        }
                    })
                }

                traversal(treeData)
                resolve(treeData)
            })
        },

        getClass(majorId) {
            this.$http.post("api/sys_office", {"parent_id": "0", pmid: "0"}).then((res) => {
                if(res.data&&res.data[0]&&res.data[0].children&&res.data[0].children.length>0){
                    this.deleteEmptyGroup(res.data[0].children).then(a => {
                        if(a&&a.length>0){
                            a.map(b=>{
                                if(b.children&&b.children.length>0){
                                    b.children.map(c=>{
                                        if(c.children&&c.children.length>0){
                                            c.children.map(d=>{
                                                d.disabled = false
                                                console.log(181,d.major_id ,majorId)
                                                if (d.major_id && d.major_id != majorId) {
                                                    d.disabled = true
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                        this.classList = a
                    })
                }
            });
        },

        formAdd() {
            this.getClass()
            this.isShowModal = true;
            this.formData = {}
            this.formData = {
                pid: 0,
                sort: 1,
                major_name: ''
            }
            this.modalTitle = '添加'
        },
        handleAdd(row) {
            this.getClass(row.id)
            this.isShowModal = true;
            this.formData = {}
            this.formData = {
                pid: row.id,
                pname: row.major_name,
                sort: 1,
            }
            this.modalTitle = '添加'
        },
        handleModify(row) {
            this.getClass(row.id)
            console.log(row)
            this.formData = {}
            this.isShowModal = true;
            this.modalTitle = '修改';
            this.formData = JSON.parse(JSON.stringify(row))
            let arr = []

            if(this.formData.classNames&&this.formData.classNames.length>0){
                this.formData.classNames.map(b=>{
                    arr.push(b.old_id)
                })
                this.formData.classids = arr
            }
        },
        handleDel(row) {
            this.$http.post('api/sys_major_delete', {
                id: row.id
            }).then(res => {
                this.$message.success('删除成功')
                this.getList();
            })
        },

        handleChange(e) {
            console.log(e)
        },

        closeDialog(){
            this.formData={}
            this.isShowModal = false;
        },
        submitForm() {
            let _this = this
            console.log(this.formData)
            let params = JSON.parse(JSON.stringify(this.formData))
            if(params.pname){
                params.major_name = params.pname
            }
            this.$http.post('api/sys_major_edit', params).then(res => {
                if(res.data.code == 200){
                    this.$message.success('保存成功')
                    this.getList();
                    this.isShowModal = false;
                }

            })
        },
        onSearch(){
            if (this.searchForm.major_name && !this.searchForm.class_id) {
                let arr = []
                if( this.tableDataAll&& this.tableDataAll.length>0){
                    this.tableDataAll.map(a=>{
                        if(a.major_name&&a.major_name.indexOf(this.searchForm.major_name)!=-1){
                            arr.push(a)
                        }
                    })
                    this.tableData = arr

                }
            }
            else if (!this.searchForm.major_name && this.searchForm.class_id) {
                let arr = []
                if( this.tableDataAll&& this.tableDataAll.length>0){
                    this.tableDataAll.map(a=>{
                        if(a.classes_ids&&a.classes_ids.indexOf(this.searchForm.class_id)!=-1){
                            arr.push(a)
                        }
                    })
                    this.tableData = arr

                }
            }
            else if (this.searchForm.major_name && this.searchForm.class_id) {
                let arr = []
                if (this.tableDataAll && this.tableDataAll.length > 0) {
                    this.tableDataAll.map(a => {
                        if ((a.major_name && a.major_name.indexOf(this.searchForm.major_name) != -1) &&
                            (a.classes_ids && a.classes_ids.indexOf(this.searchForm.class_id) != -1)) {
                            arr.push(a)
                        }
                    })
                    this.tableData = arr

                }
            } else {
                this.getList()
            }
        },
        onReset() {
            this.searchForm = {};
            this.getList();
        },
    }
}
</script>
<style type="text/less" lang="less">
.el-cascader-menu{
    height: 300px;
    .el-cascader-menu__wrap{
        height: 100%;
    }
}

</style>
